"use client";

import { useSite } from "@/contexts/site/site.context";
import type { TranslationProps, Translations } from "../translation.types";

function isModelString(
  props: TranslationProps,
): props is { readonly model: string } {
  return "model" in props && typeof props.model === "string";
}

function isTranslationsModel(
  props: TranslationProps,
): props is { readonly model: Translations } {
  return "model" in props;
}

function isTranslations(props: TranslationProps): props is Translations {
  return !("model" in props);
}

/**
 * You can pass either individual translations or a model with translations.
 * Ex. useTranslation({ en: "Hello", es: "Hola" ... }) or useTranslation({ model: { en: "Hello", es: "Hola" ... } )
 */
export function useTranslation<T>(props: TranslationProps) {
  const { locale } = useSite();

  if (isModelString(props)) {
    return props.model;
  }

  if (isTranslationsModel(props)) {
    return props.model[locale] as T;
  }

  if (isTranslations(props)) {
    return props[locale] as T;
  }
}
